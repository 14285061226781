import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { notifyEmber } from 'utils/ember-communication';
import transferLocalStorageDataToCookie from 'utils/cookies/transfer-local-storage-data-to-cookie';
import endpoint, { ErrorResponse } from 'utils/endpoint';
import useAuthenticatedRoute from 'hooks/use-authenticated-route';
import { DEFAULT_EXPIRE_DAYS, LOCATION_EXPIRE_DAYS, SITE_LOCATION_COOKIE_NAME, USER_EXPIRE_DAYS, USER_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { CountryCodeList } from 'types/countries';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import {
  acceptTermsInCookies,
  getAllAcceptedTermsByUser,
  checkZoocasaTermsAreReadInCookies,
  setZoocasaTermsAreReadInCookies,
} from 'utils/listing-agreement-helper';
import { AreaPageListing_Provider, TopCitiesResponse } from '@zoocasa/go-search';
import { HttpRequestMethodTypes } from 'types';
import defaultPopularCitiesData from 'components/suggested-location-dropdown/data.json';
import configJSON from 'config.json';
import { useFeaturesContext } from './features';
import { POPULAR_CITIES_COOKIE } from 'constants/cookies';
import { dasherizeKeys } from '@zoocasa/node-kit/objects';
import { camelize } from '@zoocasa/node-kit/strings/camelize';
import { useThemeContext, useModalContext } from './';
import { handleQuebecPopup, IsUserInQuebec } from 'utils/modals';
import useDebounce from 'hooks/use-debounce';
import { useTranslation } from 'react-i18next';
import { SiteNameSpace } from 'constants/locale';
import { fetchWithRetry } from 'utils/fetchWithRetry';

import type { IUserLocation } from 'components/home-page/location';
import type { CountryCode } from 'types/countries';
import type { SearchSuggestions } from 'components/suggested-location-dropdown';

export type User = {
  id: string | number;
  firstName: string;
  lastName: string;
  homePhone: string;
  email: string;
  jwt: string;
  newsletter: boolean;
  ssoProvider: string | null;
  hasPassword: boolean;
};

export interface ValidateUserRequest {
  email?: string;
  userId?: string;
  provider?: string;
}

export interface ValidateUserTheme {
  user_id: number;
  acceptance: string;
}

export interface ValidateUserResponse {
  auth: string[];
  firstName: string;
  theme: {
    [themeName: string]: ValidateUserTheme;
  };
}

export interface AuthErrorResponse {
  error: string;
  message: string;
}

type JSONAPIPayload = {
  data: {
    id: string | number;
    attributes: {
      firstName: string;
      lastName: string;
      homePhone: string;
      email: string;
      jwt: string;
      newsletter: boolean;
      ssoProvider: string | null;
      hasPassword: boolean;
    };
  };
};

type UserRegisterResponse = {
  data: Record<string, unknown>[];
  errors: {
    source?: {
      pointer?: string;
    };
    title?: string;
  }[];
};
type Success = { success: string };
type Error = { error: string };

export interface IUserContext {
  user: User | null;
  isAuthenticated: boolean;
  authenticateUser: (properties: Record<string, unknown>) => Promise<void | Error>;
  registerUser: (properties: Record<string, unknown>) => Promise<void | Error>;
  acceptedBrowserBasedTerms: AreaPageListing_Provider[];
  acceptedTerms: AreaPageListing_Provider[];
  acceptTerms: (providerId: AreaPageListing_Provider) => void;
  setZoocasaTermsStateToRead: () => void;
  areZoocasaTermsRead: boolean;
  confirmUser: (confirmationToken: string, userAgent: string, userLocationSlug: string, userLatitude: number, userLongitude: number) => Promise<Success | Error>;
  signOut: () => Promise<void>;
  recoverPasswordForEmail: (email: string) => Promise<Success | Error>;
  resetPassword: (params: { password: string; passwordConfirmation: string; resetPasswordToken: string }) => Promise<Success | Error>;
  updatePassword: (params: { currentPassword?: string; password: string; passwordConfirmation: string; email?: string }) => Promise<Success | Error>;
  updateUserData: (params: User) => Promise<Success | Error>;
  saveAndPersistUser: (user: User | null) => void;
  validateUser: (user: ValidateUserRequest) => Promise<ValidateUserResponse | AuthErrorResponse>;
  userLocation?: IUserLocation;
  siteLocation?: CountryCode;
  handleSetSiteLocation: (countryCode: CountryCode) => void;
  handleSetDefaultUserLocation: (countryCode: CountryCode) => void;
  hasAcceptedConnRequestDisclaimers: boolean;
  handleConnRequestDisclaimers: (isChecked: { isChecked: boolean }) => void;
  userPopularCities: SearchSuggestions[];
  getUserLocationPopularCities: (location: IUserLocation) => Promise<SearchSuggestions[]>;
  hasNavigatedFromInternalLink: boolean;
  setHasNavigatedFromInternalLink: (hasNavigatedFromInternalLink: boolean) => void;
  updateEmail: (params: { newEmail: string; retypeEmail: string; password?: string; passwordConfirmation?: string}) => Promise<Success | Error>;
}

export const UserContext = createContext<IUserContext | Record<string, unknown>>({});
export function useUserContext <T extends IUserContext | Record<string, unknown> = IUserContext>() {
  return useContext(UserContext) as T;
}

interface Props {
  user: IUserContext['user'];
  userLocation?: IUserLocation;
  storedSiteLocation?: CountryCode;
  storedConnRequestDisclaimers: boolean;
  storedUserPopularCities?: SearchSuggestions[];
  children: ReactNode;
}

export default function UserContextProvider({ user: userData, userLocation: storedUserLocation, storedSiteLocation, storedConnRequestDisclaimers, storedUserPopularCities, children }: Props) {
  transferLocalStorageDataToCookie('user');
  const { features } = useFeaturesContext();
  const { themeName } = useThemeContext();
  const { openModal } = useModalContext();
  const [user, setUser] = useState<IUserContext['user']>(userData as IUserContext['user']);
  const [isAuthenticated, setIsAuthenticated] = useState<IUserContext['isAuthenticated']>(!!(user && user.jwt));
  const [siteLocation, setSiteLocation] = useState<CountryCode | undefined>(storedSiteLocation);
  const [userLocation, setUserLocation] = useState<IUserLocation | undefined>(storedUserLocation);
  const [hasAcceptedConnRequestDisclaimers, setHasAcceptedConnRequestDisclaimers] = useState(storedConnRequestDisclaimers);
  const [hasNavigatedFromInternalLink, setHasNavigatedFromInternalLink] = useState<IUserContext['hasNavigatedFromInternalLink']>(false);

  const [acceptedBrowserBasedTerms, setAcceptedBrowserBasedTerms] = useState<AreaPageListing_Provider[]>([]);
  const [acceptedTerms, setAcceptedTerms] = useState<AreaPageListing_Provider[]>([]);
  const [areZoocasaTermsRead, setAreZoocasaTermsRead] = useState(false);

  const userPopularCities = storedUserPopularCities || [];
  const { t } = useTranslation(SiteNameSpace.FormError);

  useAuthenticatedRoute(isAuthenticated);

  useEffect(() => {
    if (!siteLocation) {
      if (!features?.useUsListings) {
        handleSetSiteLocation(CountryCodeList.CANADA);
      } else {
        if (userLocation?.countryCode === CountryCodeList.UNITED_STATES) {
          handleSetSiteLocation(CountryCodeList.UNITED_STATES);
        } else if (userLocation?.countryCode) {
          handleSetSiteLocation(CountryCodeList.CANADA);
        }
      }
    }
  }, [userLocation, siteLocation, features?.useUsListings]);

  useEffect(() => {
    if (storedUserLocation) {
      setUserLocation(storedUserLocation);
    }
  }, [storedUserLocation]);

  useEffect(() => {
    if (storedSiteLocation) {
      handleSetSiteLocation(storedSiteLocation);
    }
  }, [storedSiteLocation]);

  useEffect(() => {
    setIsAuthenticated(!!(user && user.jwt));
    checkZoocasaTermsState();
    checkAllAcceptedTerms();
    notifyEmber({ type: 'authentication' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const debouncedHandleQuebecPopup = useDebounce(() => {
    const isUserInQuebec = IsUserInQuebec(userLocation);
    handleQuebecPopup({ themeName, isQuebec: isUserInQuebec, openModal, source: 'homepage' });
  }, 300);

  useEffect(() => {
    // Handles Quebec popup on user's location
    debouncedHandleQuebecPopup();
  }, [themeName, userLocation, openModal, debouncedHandleQuebecPopup]);

  const authenticateUser: IUserContext['authenticateUser'] = async properties => {
    try {
      const response = await endpoint<JSONAPIPayload>('/services/api/v3/users/login', HttpRequestMethodTypes.POST, properties);
      if (response.data.attributes) {
        setUserFromResponse(response);
        Cookies.set('jwt', response.data.attributes.jwt, { domain: '.zoocasa.com', expires: USER_EXPIRE_DAYS });
      } else {
        return { error: t('formError:invalidPassword') };
      }
    } catch {
      return { error: t('formError:invalidPassword') };
    }
  };

  const registerUser: IUserContext['registerUser'] = async properties => {
    const response = await endpoint<UserRegisterResponse>('/services/api/v4/auth', HttpRequestMethodTypes.POST, {
      data: {
        type: 'users',
        attributes: dasherizeKeys(properties),
      },
    });
    if ('errors' in response && response.errors.length) {
      throw {
        errors: response.errors.map(({ source, title }) => {
          return {
            attribute: camelize((source?.pointer || '').replace('/data/attributes/', '')),
            message: title,
          };
        }),
      };
    } else if ('errors' in response || 'error' in response) {
      throw {
        errors: [{
          attribute: 'other',
          message: t('formError:other'),
        }],
      };
    } else {
      await authenticateUser({ email: properties.email, password: properties.password });
    }
    return response;
  };

  const checkAllAcceptedTerms = () => { // Update all states
    const { userBasedTerms, browserBasedTerms } = getAllAcceptedTermsByUser(user?.id);
    setAcceptedTerms(userBasedTerms);
    setAcceptedBrowserBasedTerms(browserBasedTerms);
  };

  const acceptTerms = (providerId: AreaPageListing_Provider) => {
    acceptTermsInCookies(providerId, user?.id);
    checkAllAcceptedTerms();
  };

  const setZoocasaTermsStateToRead = () => {
    setZoocasaTermsAreReadInCookies();
    checkZoocasaTermsState();
  };

  const checkZoocasaTermsState = () => {
    setAreZoocasaTermsRead(checkZoocasaTermsAreReadInCookies());
  };

  const validateUser = async (user: ValidateUserRequest): Promise<ValidateUserResponse | AuthErrorResponse> => {
    try {
      const response = await endpoint<ValidateUserResponse | AuthErrorResponse | ErrorResponse>('/services/api/v4/auth/next_steps', HttpRequestMethodTypes.GET, user as Record<string, unknown>);

      if ('errors' in response) {
        if (response.errors[0].error === 'User not found') {
          return { error: 'User not found', message: 'User not found' };
        }
        return { error: 'Request failed', message: 'An error occurred while validating the user' };
      } else if ('error' in response) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      console.error('An error occurred while validating the user:', error);
      return { error: 'Request failed', message: 'An error occurred while validating the user' };
    }
  };

  const confirmUser: IUserContext['confirmUser'] = async (confirmationToken, userAgent, userLocationSlug, userLatitude, userLongitude) => {
    const params = {
      confirmationToken,
      userAgent,
      userLocationSlug,
      userLatitude,
      userLongitude,
      theme: themeName,
    };
    try {
      const response = await endpoint<JSONAPIPayload>('/services/api/v3/users/confirmation', HttpRequestMethodTypes.GET, params);
      setUserFromResponse(response);
      const successMessage = 'Your account confirmation was successful. You are now logged in.';
      return { success: successMessage };
    } catch {
      return { error: 'Your account confirmation was not successful.' };
    }
  };

  const signOut: IUserContext['signOut'] = async () => {
    await endpoint('/services/api/v3/users/logout', 'DELETE');
    saveAndPersistUser(null); // Set user to null after to allow for the jwt to be included in the logout request
  };

  const recoverPasswordForEmail: IUserContext['recoverPasswordForEmail'] = email => {
    return endpoint('/services/api/v3/users/password', HttpRequestMethodTypes.POST, { email, theme: themeName }).then(() => {
      const successMessage = 'A message has been sent to reset your password. Please check your inbox.';
      return { success: successMessage };
    }).catch(() => ({ error: 'Invalid Email' }));
  };

  const resetPassword: IUserContext['resetPassword'] = params => {
    return endpoint('/services/api/v3/users/password', HttpRequestMethodTypes.PATCH, { ...params, theme: themeName }).then(() => {
      const successMessage = 'Your password was changed successfully.';
      return { success: successMessage };
    }).catch(() => ({ error: 'Reset Password Token is invalid.' }));
  };

  const updatePassword: IUserContext['updatePassword'] = params => {
    return endpoint('/services/api/v3/users/password', HttpRequestMethodTypes.PATCH, { ...params, theme: themeName }).then((response: any) => {
      if ('errors' in (response as Record<string, unknown>)) {
        const error = parseError(response.errors[0]);
        return { error };
      } else if (response.data) {
        setUserFromResponse(response);
      }
      const successMessage = 'Your password was changed successfully.';
      return { success: successMessage };
    }).catch(() => ({ error: 'Something went wrong. Please try again later.' }));
  };

  const updateUserData: IUserContext['updateUserData'] = params => {
    return endpoint(`/services/api/v3/users/${params.id}`, HttpRequestMethodTypes.PATCH, { data: { attributes: dasherizeKeys(params) }}).then(response => {
      if ('errors' in (response as Record<string, unknown>)) {
        return { error: 'is invalid' };
      }
      saveAndPersistUser({ id: params.id, attributes: { ...params }});
      const successMessage = 'Your info was changed successfully.';
      return { success: successMessage };
    }).catch(() => ({ error: 'is invalid' }));
  };

  const updateEmail: IUserContext['updateEmail'] = params => {
    const data = {
      data: {
        type: 'users',
        attributes: {
          currentEmail: user?.email,
          email: params.newEmail,
          emailConfirmation: params.retypeEmail,
          password: params.password,
          passwordConfirmation: params.passwordConfirmation,
        },
      },
    };

    return endpoint('/services/api/v3/users/email', HttpRequestMethodTypes.PATCH, data).then((response: any) => {
      if ('errors' in (response as Record<string, unknown>)) {
        const emailError = parseError(response.errors[0]);
        return { error: emailError };
      }
      if (user) saveAndPersistUser({ id: user.id, attributes: { ...user, email: params.newEmail, hasPassword: true }});
      const successMessage = 'Your email was changed successfully.';
      return { success: successMessage };
    }).catch(() => ({ error: 'is invalid' }));
  };

  const setUserFromResponse = (response: JSONAPIPayload) => {
    return saveAndPersistUser(response.data);
  };

  const saveAndPersistUser = (user: JSONAPIPayload['data'] | null) => {
    if (user) {
      const newUser: User = { id: user.id, ...user.attributes };
      Cookies.set('user', JSON.stringify(newUser), { expires: USER_EXPIRE_DAYS });
      setUser(newUser);
    } else {
      Cookies.remove('user');
      setUser(null);
    }
  };

  const handleSetSiteLocation = (location: CountryCode) => {
    Cookies.set(SITE_LOCATION_COOKIE_NAME, location, { expires: LOCATION_EXPIRE_DAYS });
    setSiteLocation(location);
    location === CountryCodeList.CANADA && document.getElementById('consent_blackbar')?.remove();
  };

  const handleSetDefaultUserLocation = (country: CountryCode) => {
    let location = defaultCACityPayload;
    if (country === CountryCodeList.UNITED_STATES) {
      location = defaultUSCityPayload;
    }
    Cookies.set(USER_LOCATION_COOKIE_NAME, JSON.stringify(location), { expires: DEFAULT_EXPIRE_DAYS });
    setUserLocation(location);
  };

  const handleConnRequestDisclaimers = ({ isChecked }: { isChecked: boolean }) => {
    if (isChecked) {
      Cookies.set('connection-request-disclaimers', 'true', { expires: USER_EXPIRE_DAYS });
      setHasAcceptedConnRequestDisclaimers(true);
    } else {
      Cookies.remove('connection-request-disclaimers');
      setHasAcceptedConnRequestDisclaimers(false);
    }
  };

  const getUserLocationPopularCities = useCallback(async (location: IUserLocation) => {
    async function getCities(countryCode: string, provinceCode?: string) {
      let resp: SearchSuggestions[];

      const provinceUrlSegment = provinceCode && provinceCode?.length > 0 ? `/${provinceCode}` : '';
      const areaPageUrl = `${configJSON.goSearchClientSideHost}/insights/popular/${countryCode}${provinceUrlSegment}?limit=10`;
      try {
        const response = await fetchWithRetry(areaPageUrl, { method: HttpRequestMethodTypes.GET });

        if (!response.ok) {
          console.error('Failed to fetch popular cities: %d %s', response.status, response.statusText);
          return [];
        }
        const content = await response.blob();
        const buffer = await content.arrayBuffer();
        const topCities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;
        resp = await Promise.all(topCities.slice(0, 10).map(async (c, index) => {
          const cityProvinceName = c.Slug.slice(-2);
          return {
            identifier: index,
            label: `${c.Name}, ${cityProvinceName?.toUpperCase()} - City`,
            urlPath: `${c.Slug}-real-estate`,
            zoom: 14,
            hasAreaPage: true,
          };
        }));
        return resp;
      } catch (error: any) {
        console.error('Failed to fetch popular cities: %s', error);
        const { usPopularSearches, canadaPopularSearches } = defaultPopularCitiesData;
        resp = siteLocation===CountryCodeList.UNITED_STATES ? usPopularSearches : canadaPopularSearches;
        return resp;
      }
    }

    const provinceCode = location.slug.split('-').pop();
    let cities = await getCities(location.countryCode, provinceCode);
    if (cities?.length == 0) {
      cities = await getCities(location.countryCode);
    }

    Cookies.set(POPULAR_CITIES_COOKIE, JSON.stringify(cities));
    return cities;
  }, [siteLocation]);

  const parseError = (error: any) => {
    if (error && error.source && error.source.pointer && error.title) {
      const pointer = error.source.pointer.split('/').pop();
      const capitalizedPointer = pointer.charAt(0).toUpperCase() + pointer.slice(1);
      const title = error.title;
      return `${capitalizedPointer.replace(/-/g, ' ')} ${title}`;
    } else {
      return 'Something went wrong. Please try again later.';
    }
  };

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        user,
        authenticateUser,
        registerUser,
        acceptTerms,
        acceptedBrowserBasedTerms,
        acceptedTerms,
        setZoocasaTermsStateToRead,
        areZoocasaTermsRead,
        confirmUser,
        validateUser,
        signOut,
        recoverPasswordForEmail,
        resetPassword,
        saveAndPersistUser,
        userLocation,
        siteLocation,
        handleSetSiteLocation,
        handleSetDefaultUserLocation,
        hasAcceptedConnRequestDisclaimers,
        handleConnRequestDisclaimers,
        userPopularCities,
        getUserLocationPopularCities,
        hasNavigatedFromInternalLink,
        setHasNavigatedFromInternalLink,
        updateUserData,
        updateEmail,
        updatePassword,
      }}>
      {children}
    </UserContext.Provider>
  );
}

function useUser(): IUserContext {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }
  return context as Required<IUserContext>;
}

export { useUser };