import React, { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';
import LoadingSkeleton from 'components/loading-skeleton';
import NoImage from './no-image';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts/theme';
import { ThemeNames } from 'types/themes';

export interface ImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  isVisible?: boolean;
  width?: string;
  height?: string;
}

export default function Image({ isVisible, width, height, src, ...imgProps }: ImageProps) {
  const [hasValidImage, setHasValidImage] = useState(!!src);
  const { themeName } = useThemeContext();
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  if (!isVisible) {
    return <LoadingSkeleton width={width} height={height} />;
  } else {
    if (hasValidImage) {
      // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
      return <img
        className={styles.component}
        onError={() => setHasValidImage(false)}
        src={src}
        {...imgProps}
      />;
    } else {
      return <NoImage isExpTheme={isExpTheme} />;
    }
  }
}
